.upload-list{
    margin-top: 0;
}

.uploadlistTitle {
    /* background-color: #ff7e00; */
    padding: 5px 15px;
    color: #fff; 
    font-size: 20px;
    font-weight: 500;
    margin-left: 50px;
}

.upload-container {
    display: flex;
    position: relative;
    padding: 5px 20px;
    width: max-content;
    transform: translateX(0px);
    transition: all 1s ease;
    /* overflow-x: auto; */
} 

.upload-wrapper {
    position: relative;
    overflow-x: scroll;
}

.upload-wrapper::-webkit-scrollbar {
    width: 0;
}

/* .wrapper > .sliderArrow {
    width: 50px;
    height: 100%;
    background-color: rgb(255,255,255, 0.8);
    color: orange;
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
} */

/* .scroll {
    overflow: scroll;
} */

.left {
    left: 0;
}

.right {
    right: 0;
}

@media only screen and (max-width: 769px){
    .upload-container {
        padding: 10px;
    }
}