.success {
    background-color: #ff7e00;
    background-size: cover;
    /* position: relative; */
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.congratulations {
    color: white;
    font-size: 50px;
    font-weight: 900;
}

.caption {
    color: white; 
    font-size: 20px;
    font-weight: 400;
    padding: 3px 7px;
    background-color: black;
}

.white {
    background-color: white;
    width: 100%;
    /* height: 500px; */
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.span {
    font-size: 25px;
    font-weight: 100;
}

.success-image {
    /* margin: 300px 43%; */

}

.success-button {
    background-color: #ff7e00;
    text-align: center;
    padding: 17px 30px;
    font-size: 15px;
    border: none;
    border-radius: 7px;
    color: white;
    cursor: pointer;
}