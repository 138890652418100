.search-list {
    /* position: absolute; */
    height: 140px;
    /* overflow: hidden; */
}

.search-list:hover {
    width: 325px;
    height: 160px;
    position: relative;
    z-index: 999;
    top: -80px;
    left: -50px;
    transition: ease-in-out 0.3s;
}

.search-list-video{
    z-index: 999;
    width: 325px;
    height: 160px;
    object-fit: cover;
    position: absolute;
    left: 0;
    border-radius: 5px;
}
