.featured {
    display: flex;
    height: 60vh;
    position: relative;
}

.featured-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
        /* background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    ); */
}

.featured-image-2 {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    object-fit: cover;
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100% );
} 

.info {
    width: 90%;
    margin-left: 20px;
    position: absolute;
    bottom: 20px;
    color: white;
    display: flex;
    flex-direction: column;
}

.info > .film-title {
    font-size: 45px;
    font-weight: 800;
}

.description {
    width: 250px;
}

.actions {
    color: black;
}

.info > .description {
    margin: 5px 0px;
    width: 400px;
    font-size: 13px;
}

.info > .genre {
    font-size: 15px;
}

.info > .rated {
    margin-bottom: 10px;
    width: 500px; 
}

    
.info > span > b{
    margin-left: 7px;
    border: 1px solid white;
    padding: 2px 2px;
    font-size: 11px;
}

.buttons {
    display: flex;
    margin: 20px 0;
}

.buttons > button {
    padding: 5px 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    color: white;
}

.play {
    background-color: gold;
    color: black;
    transition: 0.7s;
}

.link {
    text-decoration: 'none'; 
    color: 'white';
    display: 'flex';
    align-items: 'center'
}

.play:hover > .link > span{
    color: black;
    transition: 0.7s;
}

.more-info {
    background-color: gold;
    color: black;
    transition: 0.7s;
}

.more {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    width: 220px;
    /* position: absolute; */
    border-radius: 15px;
    margin-bottom: 30px;
    transition: 0.5s;
    justify-content: center;
}

.more > span {
    padding: 3px 7px;
    font-size: 13px;
}

.somemore {
    font-size: 13px;
    padding: 3px 7px
}

.search-bar{
    margin: 0 auto;
}


.more-info:hover {
    color: black;
    transition: 0.7s;
}

.buttons > button > span {
    
    margin-left: 5px;
}

.category{
    position: absolute;
    top: 80px;
    left: 50px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    z-index: 1;
}

.category > select {
    cursor: pointer;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 5px 0;
    margin-left: 25px;
}

.category > select > options {
    text-align: center;
}

@media only screen and (max-width: 920px){
    .description {
        width: 250px;
    }
}

@media screen and (max-width: 899px) {
    .description {
        font-size: 15px;

    }
}

@media only screen and (max-width: 769px){
    .featured {
        height: 60vh;
    }
    .buttons > button {
        padding: 5px 10px;
    }
    .actions {
        font-size: 11px;
    }
    .info > .film-title {
        font-size: 30px;
    }
    .info > .description {
        margin: 0;
        font-size: 13px;
    }
    .info > .rated {
        margin: 10px 0;
        width: 400px;
        font-size: 13px;
    }
    .info {
        left: 0;
        bottom: 30px;
    }
}



@media only screen and (max-width: 700px){
    .info > .film-title {
        font-size: 30px;
    }
    .description {
        width: 250px;
    }
}

@media screen and (max-width: 425px) {
    .info > .film-title {
        font-size: 20px;
    }
    .info > .description {
        width: 250px;
        font-size: 11px;
    }
    .info > .rated {
        font-size: 11px;
    }
    .info {
        width: 80%;
        left: 30;
    }
}

