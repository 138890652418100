.content-list-item {
    width: 140px;
    height: 160px;
    background-color: #000;
    color: #fff;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    /* transition: 0.2s; */
}

/* .content-list-item:hover{
    padding-bottom: 5px;
    padding-right: 5px;
}  */

.content-list-item > img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}

.content-list-item:hover > img {
    box-shadow: 0px 1px 40px 0px rgba(255,255,255,0.75);
    -webkit-box-shadow: 0px 1px 24px 0px rgba(255,255,255,0.75);
    -moz-box-shadow: 0px 1px 24px 0px rgba(255,255,255,0.75);
    transition: 0.2s;
}


.contentlistVideo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.content-title {
    color: #fff;
    font-size: 15px;
    padding: 7px 15px;
    width: 190px;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 290px;
    right: 50;
    background-color: #ff7e00;
}

.watch-now:hover{
    padding: 10px 19px;
    background-color: #ff7e00;
    top: 43%;
    left: 15%;
    transition: 0.1s;
}

@media only screen and (max-width: 769px){
    .content-list-item {
        width: 160px;
        height: 200px;
        margin-right: 15px;
    }
    
}