.watch {
    width: 100vw;
    height: 100vh;
}

.video {
    width: 100%;
    height: 100%;
}

.playwrapper {
    display: contents;
}

.back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    cursor: pointer;
    z-index: 2;
}

.watchLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    width: 100%;
    height: 100%;
}

.watching {
    color: white;
    display: flex;
    padding: 300px 0 0 150px;
    
}

.details {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.514);
}

.director {
    color: white;
    display: flex;
    margin-left: 150px;
}

.overlay-title {
    color: white;
    font-size: 60px;
    font-weight: 800;
    display: flex;
    margin-left: 145px;
}

.overlay-subtitle {
    color: white;
    display: flex;
    font-size: 20px;
    margin-left: 150px;
    margin-top: 10px;
    width: 650px;
}

@media only screen and (max-width: 425px) {
    .watch {
        transform: rotate(-90deg);
        transform-origin: left top;
        /* Set content width to viewport height */
        width: 100vh;
        /* Set content height to viewport width */
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }
    .c-left {
        display: none;
    }
    .c-right {
        display: none;
    }
}