.modalBackgroun-d {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.356);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.modalContaine-r {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    border: 0.5px solid white;
    justify-content: space-between;
    background-color: black;
    box-shadow: rgba(173, 173, 173, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modImage {
    position: relative;
    transition: 0.4s;
    cursor: pointer;
}

.label {
    font-size: 12px;
    color: white;
    margin: 10px;
}

.mod-picture{
    width: 100px;
    height: 100px;
    border: 5px solid yellow;
    border-radius: 50%;
    object-fit: cover;
}

.uploade-d{
    font-size: 12px;
    width: 200px;
    margin-left: 15px;
}


.modImage:hover .moddLogo{
    color: white;
    cursor: pointer;
    transition: 0.4s
}

.mod-container {
    margin-top: 30px
}

.mod-type {
    /* border: 1px solid black; */
    padding: 0 10px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.p-mod-input {
    outline: none;
    padding: 10px 20px;
    background-color: black;
    border-bottom: 0.5px solid white;
    border-top: none;
    border-left: none;
    width: 90%;
    border-right: none;
    color: white;
}

.message {
    color: white
}

.visinput {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.end {
    display: flex;
    padding: 0 15px;
    justify-content: end;
    align-items: center;
}

.update {
    margin-top: 10px;
    background-color: yellow;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 800;
    color: black;
    transition: 0.4s;
}

.update:hover{
    cursor: pointer;
    transition: 0.4s;
    box-shadow: -1px 1px 7px -1px rgba(0,0,0,0.67);
    -webkit-box-shadow: -1px 1px 7px -1px rgba(0,0,0,0.67);
    -moz-box-shadow: -1px 1px 7px -1px rgba(0,0,0,0.67);
}