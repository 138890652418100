.search-background {
    background-color: black;
    width: 100%;
    padding: 80px 0;
    position: absolute;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.search {
    display: flex;
}

.searchs-input {
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 30px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.329);
    color: white;
    border-bottom: 1px solid white;
    border-top: none;
    border-left: none;
    border-right: none;
}

.search-container {
        display: flex;
        justify-content: center;
        width: 85%;
        margin: 30px auto;
        /* position: relative; */
        align-items: center;
}

.search-list-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.search-list-wrapper {
    margin: 0px 7.5px 50px 7.5px;
    width: 225px;
    height: 140px;
    background-color: black;
    position: relative;
    color: #fff;
    /* overflow: auto; */
    cursor: pointer;
    /* z-index: 999; */
    /* transition: ease-in-out 0.3s; */
}

/* .search-list:hover > .search-image {
    height: 140px;
} */

.search-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.search-image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-desc {
    display: flex;
}

.search-itemInfo {
    /* height: 170px; */
    position: relative;
    font-size: 14px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: rgb(32, 32, 32);
    color: white;
}

.itemIcons {
    display: flex;
    margin-bottom: 10px;
}


.itemIcon {
    border: 2px solid white;
    padding: 5px;
    border-radius: 50%;
    align-self: center;
    margin-right: 65px;
    font-size: 16px;
}

.search-orange {
    background-color: #ff7e00;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-item-caption {
    display: flex;
    font-size: 13px;
    /* margin-bottom: 10px; */
    margin: 7px 0;
}

.limit {
border: 1px solid white;
background-color: white;
color: black;
padding: 1px 3px;
font-size: 14px;
margin: 0 10px;
}

@media only screen and (max-width: 1000px){
    /* .search {
     display: flex;
    }

    .search-container {
        margin: 30px auto;
} */
}


@media only screen and (min-width: 1050px) and (max-width: 1300px) {
    .search-list-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1049px) {
    .search-list-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 500px) and (max-width: 799px) {
    .search-list-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 499px) {
    .search-background {
        position: fixed;
    }
    .search-list-container {
        grid-template-columns: 1fr;
    }
}