.navbar {
    width: 100%;
    background-color: linear-gradient(to top, transparent 0%, rgb(255, 255, 255, 0.3) 50%);
    color: #fff;
    font-size: 14px;
    position: fixed;
    top: 0;
    z-index: 999;
}

.logo {
    font-weight: bold;
    font-size: 30px;
    color: gold;
    cursor: pointer;
}

.avatar{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;
    margin: 0 10px;
}

.dropdown {
    margin-top: 28px;
    margin-bottom: 30px;
    /* margin-right: 20px; 
    position: relative;
    display: inline-block; */
    /* margin-right: 20px; */
    position: fixed;
    left: 0px;
    /* display: inline-block; */
    top: 0px;
    display: none;
}

.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }

.dropdown-content {
    display: none;
    /* position: absolute;
    left: -220px;
    background-color: rgb(0 0 0 / 70%);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 15px; */
    margin: 0;
    padding: 0;
    display: none;
    flex-direction: column;
    /* align-items: center; */
    width: 200px;
    background-color: rgb(0, 0, 0);
    border-right: 0.3px solid rgba(255, 255, 255, 0.548);
    position: fixed;
    height: 100%;
    overflow: auto;
  }

.scrolled {
    width: 100%;
    background-color: rgb(0, 0, 0, 0.9);
    color: white;
    font-size: 14px;
    position: fixed;
    top: 0;
    z-index: 999;
}

.nav-container{
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.nav-left {
    display: flex;
    align-items: center;
}

.nav-left > span {
    margin-right: 20px;
    cursor: pointer;
}

.mennu > span:hover{
    color: #ff7e00;
    transition: 0.3s;
}

.mennu > p:hover{
    color: #ff7e00;
    transition: 0.3s;
}

.nav-left > img {
    margin-right: 40px;
}

.nav-right {
    display: flex;
    align-items: center;
}

.hello {
    display: inline;
    transition: 0.4s;
}

.hello:hover {
    color: #ff7e00;
    transition: 0.4s;
}

.nav-right > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.icon {
    margin: 0px 15px;
}

.profile > span {
    padding: 5px;
}

.profile > .options {
    display: none;
    background-color: black;
    border-radius: 5px;
    padding: 10px 20px;
}

.profile:hover > .options {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    /* align-items: center; */
}

.content {
    height: 100vh;
}

.searchbar {
    position: relative;
}

.searchs-input-s {
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 30px;
    display: flex;
    width: 100px;
    outline: none;
    background-color: rgba(51, 51, 51, 0.329);
    color: white;
    border-bottom: 1px solid white;
    border-top: none;
    border-left: none;
}

.search-s{
    margin: 0 10px;
    cursor: pointer;
}

.options {
    background-color: black;
}

.longbar {
    display: contents;
}

.drop-option {
    margin: 10px 0;
    transition: 0.3s;
    /* border-bottom: 1px solid white; */
}

.drop-option:hover {
    color: #ff7e00;
    transition: 0.3s;
}

/* @media screen and (max-width: 1000px) {
    
    .nav-center {
        display: none;
    }
    
} */

@media only screen and (max-width: 768px){
    .logo {
        font-size: 24px;
    }
    .dropdown {
        margin-top: 60px; 
        display: flex;
    }
    .longbar {
        display: none;
    }
}

@media only screen and (max-width: 550px){
    .nav-container {
        padding: 0px 20px;
    }
    .logo {
        font-size: 20px;
    }
    .options {
        background-color: black;
        font-size: 10px;
    }
    .nav-right > span{
        font-size: 9px;
    }
}

@media screen and (max-width: 450px) {
    .nav-right > img {
        width: 20px;
        height: 20px;
    }

    .avatar {
        height: 20px;
        width: 20px;
        margin: 0;
    }
}
