.login {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    );
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

/* .register-jumbotron{
    position: absolute;
    width: auto;
    height: 100%;
} */

.my-top-container {
    display: flex;
    /* position: absolute; */
    top: 0;
    justify-content: space-between;
    align-items: center;
}

.sign-form-container {
    width: 100%;
    /* position: absolute; */
    /* top: 230px; */
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.my-logo {
    margin-left: 20px;
    display: flex;
    font-weight: bold;
    font-size: 30px;
    color: gold;
    cursor: pointer;
    padding: 20px 10px;
    z-index: 1;
}

.my-signin-button{
    margin-right: 30px;
    padding: 20px 10px;
    z-index: 1;
}

.my-button {
    background-color: gold;
    text-align: center;
    padding: 7px 15px;
    width: 75px;
    border: none;
    color: white;
    cursor: pointer;
}

/* .log-login-form { */
    /* width: 50%; */
    /* height: 50px; */
    /* border-radius: 10px; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* background-color: transparent; */
    /* align-items: center; */
    /* border: 1px solid white; */
/* } */


.log-login-form {
    width: 65%;
    align-items: center;
    /* height: 50px; */
    /* border-radius: 10px; */
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: center;
    /* border: 1px solid white; */
    flex-direction: column;
}

.warning {
    color: white;
    font-weight: 600;
}

/* .log-login-input { */
    /* display: flex; */
    /* align-items: center; */
    /* height: 30px; */
    /* background-color: black; */
    /* opacity: 0.7; */
    /* flex: 9; */
    /* height: 100%; */
    /* padding: 0 10px; */
    /* outline: none; */
    /* border: none; */
    /* color: white; */
    /* border: 1px solid white; */
    /* border-radius: 15px; */
/* } */

.log-login-input {
    margin: 10px auto;
    width: 90%;
    border: 1px solid white;
    padding: 10px 10px;
    width: 70%;
    border: 1px solid white;
}

.log-login-input::-webkit-input-placeholder{
    color: white;
}

.logins-button {
    background-color: black;
    text-align: center;
    padding: 10px;
    margin: 10px 0;
    height: 100%;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: 0.2s;
    width: fit-content;
}

.login-button:hover{
    background-color: #ff7e00;
    border: none;
    transition: 0.2s;
}

.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.intro > span {
    color: white;
    font-size: 50px;
    font-weight: 100;
}


/* 190 pixels and below */
@media screen and (max-width: 190px) {
    .my-logo{
        font-size: 10px;
        margin: 0;
    }
}

@media screen and (max-width: 424px) { 
    .login{
        position: fixed;
    }

    /* .my-top-container {
        position: fixed;
    } */

    .log-login-form {
        position: fixed;
        margin-top: 150px;
    }
    .intro > span {
        font-size: 17px;
    }
    .log-login-input {
        font-size: 13px;
    }
    input[type="email" i] {
        font-size: 13px;
    }
    input[type="password" i] {
        font-size: 13px;
    }
}

@media screen and (max-width: 768px) {
    .log-login-form {
        width: 100%;
        height: 40px;
        /* border-radius: 10px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;
        align-items: center;
        /* border: 1px solid white; */
    }   
    .log-login-input {
        margin: 10px auto;
        width: 90%;
        border: 1px solid white;
    
    }   
    .logins-button {
        padding: 10px;
        width: 93%;
    }
    .intro > span {
        font-size: 25px;
    }
    .my-button {
        padding: 4px 7px;
    }

    
}

@media only screen and (max-width: 916px) {
    .intro > span {
        font-weight: 300;
        font-size: 20px;
        padding: 0px 20px;
        text-align: center;
    }
}

@media only screen and (min-width: 917px) {
    .intro > span {
        font-size: 30px;
    }
}






