.log-login {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    );
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    justify-content: center;
    align-items: center;
}

.top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.my-logo {
    margin-left: 20px;
    display: flex;
    font-weight: bold;
    font-size: 30px;
    color: #ff7e00;
    cursor: pointer;
    padding: 20px 10px;
}

.signin-button{
    margin-right: 30px;
    padding: 20px 10px;
}

.button {
    background-color: #ff7e00;
    text-align: center;
    padding: 7px 15px;
    border: none;
    color: white;
    cursor: pointer;
}

.log-container {
    color: white;
    align-items: center;
    width: 40%;
}

.log-form {
    /* width: 80%;  */
    height: 400px;
    padding: 30px;
    border: 1px solid white;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-around ;
}

.log-form > input {
    height: 40px;
    background-color: black;
    opacity: 0.7;
    border: 1px solid white;
    color: white;
    outline: none;
    padding-left: 10px;
}

.input-password {
    position: relative;
}

.visibility {
    position: absolute;
    right: 15px;
    top: 8px;
    color: white;
}

.visibility:hover {
    cursor: pointer;
}

.input-password > input {
    height: 40px;
    background-color: black;
    opacity: 0.7;
    border: 1px solid white;
    color: white;
    outline: none;
    padding-left: 10px;
    width: -webkit-fill-available;
}

.input-password > .red::-webkit-input-placeholder {
    color: red;
    opacity: 1;
    font-weight: 800;
}

.log-form > .red::-webkit-input-placeholder {
    color: red;
    opacity: 1;
    font-weight: 800;
}

.input-password > input::-webkit-input-placeholder {
    color: white;
    opacity: 0.5;
}

.log-button {
    z-index: 1;
    height: 40px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.signup-span {
    font-size: 20px;
}

.login-form {
    width: 50%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background-color: rgb(31, 30, 30);
    align-items: center;
    margin-top: 50px;
}



.log-login-input {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: black;
    opacity: 0.7;
    flex: 9;
    height: 100%;
    padding: 0 10px;
    outline: none;
    border: none;
    color: white;
}


.login-button {
    background-color: black;
    text-align: center;
    height: 100%;
    flex: 3;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: 0.2s;
}

.login-button:hover{
    background-color: #ff7e00;
    border: none;
    transition: 0.2s;
}

.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.intro > span {
    color: white;
    font-size: 50px;
    font-weight: 100;
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
    .log-container {
        color: white;
        align-items: center;
        width: 60%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
    .log-container {
        color: white;
        align-items: center;
        width: 60%;
    }
    .sign-in{
        font-size: 20px;
    }
    .signup-span {
        font-size: 15px;
    }
    input[type="email" i] {
        font-size: 15px;
    }
    input[type="password" i] {
        font-size: 15px;
    }
    .log-form > input::-webkit-input-placeholder {
        color: white;
    }
}

@media only screen and (max-width: 555px) {
    .log-container {
        color: white;
        align-items: center;
        width: 100%;
    }
    .signup-span {
        font-size: 15px;
    }
    .log-form {
        border: none;
    }
    .sign-in{
        font-size: 20px;
    }
    input[type="email" i] {
        font-size: 15px;
    }
    input[type="password" i] {
        font-size: 15px;
    }
    .log-form > input::-webkit-input-placeholder {
        color: white;
    }
}

