.list{
    width: 100%;
}

.listTitle {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-left: 20px;
}
/* 
.wrapper > .container {
    margin-left: 50px;
    display: flex;
    width: max-content;
    transform: translate(0px);
} */


.list-item-2 {

}

.list-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transition: 0.3s; */
}

.container {
    margin-left: 20px;
    /* margin-left: 50px; */
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    width: max-content;
    transform: translateX(0px);
    transition: all 1s ease;
}

.wrapper {
    z-index: 1;
    position: relative;
    /* overflow-x: auto; */
    /* padding: 90px 0 */
}

.wrapper::-webkit-scrollbar {
    width: 0;
}

.wrapper > .sliderArrow{
    width: 50px;
    height: 100%;
    background-image: linear-gradient(to right, black 40% , transparent);
    color: rgb(255 255 255 / 80%);
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.wrapper > .sliderArrow-s {
    width: 50px;
    height: 100%;
    background-image: linear-gradient(to right, transparent, black 40%);
    color: rgb(255 255 255 / 80%);
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

/* .scroll {
    overflow: scroll;
} */

.left {
    left: 0;
}

.right {
    right: 0;
}

@media only screen and (min-width: 280px) and (max-width: 350px){
    .listTitle {
        font-size: 20px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 279px){
    .listTitle {
        display: none;
    }
}

@media only screen and (max-width: 769px){
    .listTitle {
        font-size: 11px;
        margin-left: 10px;
    }
    .container {
        margin-left: 10px;
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        width: max-content;
        transform: translateX(0px);
        transition: all 1s ease;
    }

    .wrapper {
        overflow-x: auto;
    }
}